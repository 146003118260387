<template>
  <div id="plan-list">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus-circle"
          @click="onCreateClick"
          >{{ $l.get("dictionary.create-button") }}</vs-button
        >
      </div>
      <vs-table
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        v-if="data"
        :data="data.plansPage.results"
      >
        <template slot="header">
          <h3>{{ $l.get("plan-list.table-header") }}</h3>
        </template>
        <template slot="thead">
          <vs-th sort-key="name">{{ $l.get("plan-list.table-name") }}</vs-th>
          <vs-th>{{ $l.get("dictionary.webhook") }}</vs-th>
          <vs-th>{{ $l.get("plan-list.table-actions") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>{{ SERVER_ADDRESS }}/webhooks/plans/{{ tr.id }}/</vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="edit"
                @click="onPlanEditClick(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="margin-top: 20px; text-align: center">
        <vs-pagination
          :total="Math.ceil(data.plansPage.count / 25)"
          v-model="page"
          v-if="data"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SelectPlanEditInteractor from "@/business/plan-list/select-plan-edit";
import SelectCreateInteractor from "@/business/plan-list/select-create";
import InitPlanListInteractor from "@/business/plan-list/init-plan-list";
import ChangeFilterInteractor from "@/business/plan-list/change-filter";
import PlanListScreenController from "@/adapters/controllers/screen-plan-list";
import Loader from "@/application/components/Loader";

import { SERVER_ADDRESS } from "@/commons/constants";

export default {
  screen_name: "plan-list",
  components: { Loader },
  data() {
    return {
      SERVER_ADDRESS,
      controller: null,
      interactors: {
        selectPlanEdit: null,
        selectCreate: null,
        initPlanList: null,
        changeFilter: null
      },
      isLoading: false,
      page: 1,
      search: "",
      sort: "",
      searchTimer: null,
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(PlanListScreenController);

    //{ INTERACTORS
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.initPlanList = this.$injector.get(InitPlanListInteractor);
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    this.interactors.selectPlanEdit = this.$injector.get(
      SelectPlanEditInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initPlanList.handle();
  },
  watch: {
    page: function() {
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    handleSearch(searching) {
      this.search = searching;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      const _ = this;
      this.searchTimer = setTimeout(() => {
        _.searchTimer = null;
        if (_.page != 1) {
          _.page = 1; // watch force update
        } else {
          _.interactors.changeFilter.handle(_.page, _.search, _.sort);
        }
        //
      }, 500);
    },
    handleSort(key, active) {
      if (key) {
        if (active) {
          const prefix = active == "desc" ? "-" : "";
          this.sort = `${prefix}${key}`;
        } else {
          this.sort = "";
        }
        this.interactors.changeFilter.handle(this.page, this.search, this.sort);
      }
    },
    onPlanEditClick(data) {
      this.interactors.selectPlanEdit.handle(data);
    },
    onCreateClick() {
      this.interactors.selectCreate.handle();
    }
  }
};
</script>

<style lang="scss"></style>
